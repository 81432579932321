import {
    useParams,
    useNavigate,
    useSearchParams
} from "react-router-dom";


const withNavigation = (Component) => {
    return props => (
        <Component
            {...props}
            navigate={useNavigate()}
            params={useParams()}
            searchParams={useSearchParams()} />
    );
};

export default withNavigation;
