import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    Spinner,
    Table,
    Accordion,
} from 'react-bootstrap';

import { Navigate } from "react-router-dom";

import { NotificationContainer, NotificationManager } from 'react-notifications';


import Header from '../Layout/default/header';
import Footer from '../Layout/default/footer';

import QRCode from "react-qr-code";

import withNavigation from '../common/withNavigation';
import axios from 'axios';
import config from '../../config/core';
import cookieman from '../common/cookieman';
import LoadingOverlay from '../common/loadingOverlay';

import validateSession from '../common/validateSession';

class Consultar extends Component {
    state = {
        loading: true,
        error: null,
        membresia: null,
        showPuntosModal: false,
        showRedimirPuntosModal: false,
        importe: 0,
        puntos: 0,
        descripcion: ''
    };

    componentDidMount() {
        if (this.state.loading) {
            const url = process.env.REACT_APP_BACKEND + '/membresias/consultar/' + this.props.params.membresia_uuid;

            axios({
                url: url,
                type: 'get',
                headers: {
                    token: cookieman.getItem('token')
                },
            }).then(result => {
                if (result.data.success) {

                    this.setState({
                        ...this.state,
                        membresia: result.data.data
                    });
                }
                else {
                    <Navigate to="/home" />
                    this.setState({
                        ...this.state,
                        error: result.data.msg
                    });
                }
            }).catch(error => { }).finally(() => {
                <Navigate to="/home" />
            }).finally(() => {
                this.setState({
                    ...this.state,
                    loading: false
                });
            });
        }
    }

    handleShowModal(show) {
        this.setState({
            ...this.state,
            showPuntosModal: show
        });
    }

    handleShowRedimirPuntosModal(show) {
        this.setState({
            ...this.state,
            showRedimirPuntosModal: show
        });
    }

    render() {
        validateSession();

        let vigencia_desde = new Date();
        let vigencia_hasta = new Date();

        let historial = null;

        if (this.state.membresia !== null) {
            vigencia_desde = new Date(this.state.membresia.vigencia_desde);
            vigencia_hasta = new Date(this.state.membresia.vigencia_hasta);

            historial = this.state.membresia.historial_puntos.map(registro => {
                const fecha = new Date(registro.fecha_registro);

                let style = {
                    color: 'green'
                };

                if (registro.puntos < 0) {
                    style = {
                        color: 'red'
                    };
                }

                const importe = Number.parseFloat(registro.importe);

                return (<tr key={registro.id}>
                    <td>{fecha.toLocaleDateString()}</td>
                    <td>{registro.descripcion}</td>
                    <td className='text-center'>${importe.toFixed(2)}</td>
                    <td className='text-center' style={style}><b>{registro.puntos}</b></td>
                </tr>);
            });
        }


        const membresia = {
            ...this.state.membresia
        };


        return (
            <>
                {this.state.loading ? <LoadingOverlay /> : null}
                <Header mustHaveSession={true} />
                <Container style={{ minHeight: window.innerHeight * .8 }}>
                    <Row>
                        <Col>
                            <h1 className="text-center">{this.state.loading ? <Spinner /> : this.state.membresia.nombre}</h1>
                            <h2 className="text-center">Datos de la membresia</h2>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={6} sm={12}>
                            <Table striped bordered hover>
                                <tbody>
                                    <tr>
                                        <th>Negocio</th>
                                        <td>
                                            {
                                                this.state.loading ? <Spinner variant="primary" /> :
                                                    <>
                                                        {this.state.membresia.nombre_negocio}
                                                    </>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Cliente</th>
                                        <td>
                                            {
                                                this.state.loading ? <Spinner variant="primary" /> :
                                                    <>
                                                        {this.state.membresia.cliente_nombre} {this.state.membresia.cliente_primer_apellido} {this.state.membresia.cliente_segundo_apellido}
                                                    </>
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Correo</th>
                                        <td>
                                            {
                                                this.state.loading ? <Spinner variant="primary" /> : this.state.membresia.cliente_correo
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Telefono</th>
                                        <td>
                                            {
                                                this.state.loading ? <Spinner variant="primary" /> : this.state.membresia.cliente_telefono
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Precio</th>
                                        <td>
                                            {
                                                this.state.loading ? <Spinner variant="primary" /> : "$" + this.state.membresia.precio
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Puntos</th>
                                        <td>
                                            {
                                                this.state.loading ? <Spinner variant="primary" /> : this.state.membresia.puntos
                                            }
                                        </td>
                                    </tr>
                                    <tr>
                                        <th>Vigencia</th>
                                        <td>
                                            {
                                                this.state.loading ? <Spinner variant="primary" /> :
                                                    <>
                                                        {vigencia_desde.toLocaleDateString()} hasta {vigencia_hasta.toLocaleDateString()}
                                                    </>
                                            }
                                        </td>
                                    </tr>

                                </tbody>
                            </Table>
                        </Col>
                        <Col md={6} sm={12}>
                            <p>Beneficios</p>
                            {
                                this.state.loading ? <Spinner /> :
                                    this.state.membresia.beneficios.length < 1 ?
                                        <p className="text-center">No se han reclamado beneficios</p> :
                                        this.state.membresia.beneficios.map((beneficio, i) => {
                                            return (
                                                <p key={i}>{beneficio.descripcion}</p>
                                            );
                                        })
                            }
                        </Col>
                    </Row>
                    <Row>
                        <Accordion defaultActiveKey="-1">
                            <Accordion.Item eventKey="0">
                                <Accordion.Header>Historial de puntos</Accordion.Header>
                                <Accordion.Body>
                                    <Table striped bordered hover>
                                        <thead>
                                            <tr>
                                                <th>Fecha</th>
                                                <th>Concepto</th>
                                                <th className="text-center">Importe</th>
                                                <th className="text-center">Puntos</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                this.state.loading ? <tr><td colSpan={4}><Spinner /></td></tr> : historial
                                            }
                                        </tbody>
                                    </Table>
                                </Accordion.Body>
                            </Accordion.Item>
                            {false ? <Accordion.Item eventKey="1">
                                <Accordion.Header>Historial de beneficios</Accordion.Header>
                                <Accordion.Body>
                                    En construcción
                                </Accordion.Body>
                            </Accordion.Item> : null }
                        </Accordion>
                        <Col>

                        </Col>
                    </Row>

                    <Row style={{marginTop: '15px'}}>
                        <Col className="text-center">
                            <QRCode value={window.location.origin + '/membresia/' + membresia.uuid} />
                        </Col>
                    </Row>
                </Container>
                <Footer />
                <NotificationContainer />
            </>
        );
    }
}

export default withNavigation(Consultar);