import { useState } from 'react';
import {
    Col,
    Card,
} from 'react-bootstrap';

import { Navigate } from 'react-router-dom';

const CardMembresia = (props) => {
    const [redirect, setRedirect] = useState(null);

    const beneficios = [];

    props.beneficios.forEach(beneficio => {
        beneficios.push(
            <li key={Math.random()}>{beneficio.descripcion}</li>
        );
    });

    return (
        <>
            {redirect}
            <Col style={{ cursor: 'pointer' }} lg={4} sm={12} onClick={() => {
                setRedirect(<Navigate to={'/membresia/' + props.uuid} />);
            }}>
                <Card style={{
                    height: "500px",
                    marginTop: '15px'
                }}>
                    <Card.Body className="d-flex flex-column">
                        <Card.Title>
                            <h3 className="text-center">{props.negocio}</h3>
                            <p>{props.titulo} ${props.precio}</p>
                        </Card.Title>
                        <div style={{ height: '380px', overflowY: 'scroll' }}>
                            <p>{props.descripcion}</p>
                            <p>Beneficios</p>
                            <ul>
                                {beneficios}
                            </ul>
                            {props.aclaraciones !== null ? (<div dangerouslySetInnerHTML={{ __html: props.aclaraciones }}></div>) : null}
                        </div>
                    </Card.Body>
                </Card>
            </Col>
        </>
    );
};

export default CardMembresia;