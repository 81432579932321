import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

import axios from 'axios';

import config from '../../config/core';
import cookieman from '../common/cookieman';

import Header from '../Layout/default/header';
import Footer from '../Layout/default/footer';

import CardMembresia from '../Elements/CardMembresia';

class Home extends Component {
    state = {
        loading: true,
        usuario: null,
        membresias: [],
        redirect: null
    };

    componentDidMount() {
        
        const url_membresias = config.backend + '/membresias/cliente';

        axios.get(url_membresias, {
            headers: {
                token: cookieman.getItem('token')
            }
        }).then(response => {
            if (response.data.success) {
                this.setState({
                    ...this.state,
                    membresias: response.data.data
                });
            }
        });
        
    }

    componentDidCatch(e) {
        
    }

    render() {
        //return (<></>);

        const membresias_cards = [];

        this.state.membresias.forEach(membresia => {
            
            membresias_cards.push(
                <CardMembresia
                    key={membresia.uuid}
                    uuid={membresia.uuid}
                    slug={membresia.slug}
                    titulo={membresia.nombre}
                    precio={membresia.precio}
                    descripcion={membresia.descripcion}
                    beneficios={membresia.beneficios}
                    aclaraciones={membresia.aclaraciones}
                    negocio={membresia.nombre_negocio} />);
        });


        return (
            <>
                <Header mustHaveSession={true}></Header>
                <Container>
                    <Row className="justify-content-md-center">
                        {membresias_cards}
                        <Col key={0} lg={4} sm={4}  style={{marginTop: '15px'}}>
                            <Card style={{
                                height: "250px"
                            }}>
                                <a href="/membresia/aceptar/qr" className="text-center">
                                    <FontAwesomeIcon icon={faCirclePlus} size="6x" style={{ margin: "30px", marginTop: '45px' }} />
                                    <Card.Title className="text-center">Agregar membresía</Card.Title>
                                </a>
                            </Card>
                        </Col>
                    </Row>
                </Container>
                <Footer></Footer>
            </>
        );
    }
}

export default Home;