import React, { Component } from 'react';

import {
    Row,
    Col,
    Button,
    FloatingLabel,
    Form,
    FormControl,
    InputGroup,
    Card
} from 'react-bootstrap';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSave, faClose } from '@fortawesome/free-solid-svg-icons';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import ContainerAutoHeight from '../common/ContainerAutoHeight';

import Header from '../Layout/simple/header';
import Footer from '../Layout/default/footer';
import Menu from '../common/Menu';

import withNavigation from '../common/withNavigation';

import axios from 'axios';
import config from '../../config/core';
import cookieman from '../common/cookieman';
import loadingOverlay from '../common/loadingOverlay';


class Formulario extends Component {
    constructor(props) {
        super(props);

        this.state = {
            borrado: 0,
            correo: '',
            fecha_acepta_invitacion: '',
            fecha_registro: '',
            nombre: '',
            nombre_negocio: '',
            perfil: {},
            primer_apellido: '',
            segundo_apellido: '',
            telefono: '',
            ultima_actualizacion: '',
            ultimo_inicio_sesion: '',
            uuid: '',
            loading: true,
            error: null,
            redirect: null
        };
    }

    componentDidMount() {

        const url = process.env.REACT_APP_BACKEND + '/usuarios/settings';
        axios.get(url, {
            headers: {
                token: cookieman.getItem('token')
            }
        }).then(response => {

            const settings = response.data;

            this.setState({
                ...this.state,
                ...settings,
                loading: false,
            });

        }).catch(error => {
            this.setState({
                ...this.state,
                //redirect: <Navigate to="/home" />
            });
        });
    }

    setProp = (prop, value) => {
        const newState = { ...this.state };
        newState[prop] = value;

        this.setState(newState);
    }

    guardar() {
        this.setProp('loading', true);

        const url = process.env.REACT_APP_BACKEND + '/usuarios/settings';

        axios.put(url, {
            uuid: this.state.uuid,
            correo: this.state.correo,
            nombre: this.state.nombre,
            primer_apellido: this.state.primer_apellido,
            segundo_apellido: this.state.segundo_apellido,
            telefono: this.state.telefono,
            nombre_negocio: this.state.nombre_negocio,
        }, {
            headers: {
                token: cookieman.getItem('token')
            }
        }).then(response => {
            NotificationManager.success('Membresía actualizada', 'Éxito!');
        }).catch(error => {
            NotificationManager.error(error, 'Error!');
        }).finally(_ => {
            this.setProp('loading', false);
        });
    }

    render() {

        return (
            <>
                {this.state.loading ? loadingOverlay() : null}
                {this.state.redirect}
                <Header />
                <ContainerAutoHeight fluid>
                    <Row>
                        <Menu />
                        <Col sm={12} md={5} lg={5}>
                            <h1 className='text-center mt-1rem mb-1rem'>Tu cuenta</h1>
                            <Form.Group className='mb-3'>
                                <FloatingLabel htmlFor="NombreTitular" label="Nombre">
                                    <Form.Control type="text" id="NombreTitular" placeholder="¿Cuál es tu nombre?" onChange={e => {
                                        this.setProp('nombre', e.target.value);
                                    }} value={this.state.nombre} />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <FloatingLabel htmlFor="PrimerApellidoTitular" label="Primer Apellido">
                                    <Form.Control type="text" id="PrimerApellidoTitular" placeholder="¿Cuál es tu primer apellido?" onChange={e => {
                                        this.setProp('primer_apellido', e.target.value);
                                    }} value={this.state.primer_apellido} />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <FloatingLabel htmlFor="SegundoApellidoTitular" label="Segundo Apellido">
                                    <Form.Control type="text" id="SegundoApellidoTitular" placeholder="¿Cuál es tu segundo apellido?" onChange={e => {
                                        this.setProp('segundo_apellido', e.target.value);
                                    }} value={this.state.segundo_apellido} />
                                </FloatingLabel>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <FloatingLabel htmlFor="Correo" label="Correo electrónico">
                                    <Form.Control type="text" id="Correo" placeholder="¿Cual es tu correo?" onChange={e => {
                                        this.setProp('correo', e.target.value);
                                    }} value={this.state.correo} />
                                </FloatingLabel>
                                <Form.Text muted>
                                    NOTA: Esta dirección de correo será usada para iniciar sesión. Ten cuidado al actualizarla.
                                </Form.Text>
                            </Form.Group>
                            <Form.Group className='mb-3'>
                                <FloatingLabel htmlFor="Telefono" label="Teléfono">
                                    <Form.Control type="text" id="Telefono" placeholder="¿Cuál es tu número de teléfono?" onChange={e => {
                                        this.setProp('telefono', e.target.value);
                                    }} value={this.state.telefono} />
                                </FloatingLabel>
                            </Form.Group>
                            <div className="d-grid gap-2 mt-1rem">
                                <Button variant="primary" size="lg" onClick={e => {
                                    this.guardar();
                                }}><FontAwesomeIcon icon={faSave} /></Button>
                            </div>
                        </Col>
                    </Row>
                </ContainerAutoHeight>
                <Footer />
                <NotificationContainer />
            </>
        );
    }
}

export default withNavigation(Formulario);