import React from 'react';

import cookieman from '../common/cookieman';


import {
    Navigate
  } from "react-router-dom";
  

export default () => {
    cookieman.deleteItem('token');
    
    return (<Navigate to="/login" />);
};