import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

const Footer = () => {
    return (
        <Container>
            <Row>
                <Col>
                    
                </Col>
            </Row>
        </Container>
    );
};

export default Footer;