import { useState } from 'react';
import {
    Navbar,
    Container,
    Nav,
    NavDropdown,

} from 'react-bootstrap';

import { Navigate } from 'react-router-dom';

import logoWhite from '../../assets/vector/logo.svg';

import jwt_decode from 'jwt-decode';

import cookieman from '../../common/cookieman'
import isValidSession from '../../common/isValidSession';

const Header = (props) => {
    const [redirect, setRedirect] = useState(null);
    let session = null;

    if (cookieman.checkItem('token') !== null) {
        session = jwt_decode(cookieman.getItem('token'));
    }

    if (props.mustHaveSession && !isValidSession() && redirect == null) {
        setRedirect(<Navigate to="/logout" />);
    }

    return (
        <>
            {redirect}
            <Navbar bg="white" expand="lg" sticky="top" >
                <Container>
                    <Navbar.Brand href="/">
                        <img src={logoWhite} />
                    </Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            {
                                session !== null ?
                                    <Nav.Link href="/home">Home</Nav.Link>
                                    :
                                    null
                            }
                        </Nav>
                        {
                            session != null ?
                                (<NavDropdown title={session.nombre + ' ' + session.primer_apellido} id="basic-nav-dropdown" className="d-flex">
                                    <NavDropdown.Item href="/configuracion">Configuración</NavDropdown.Item>
                                    <NavDropdown.Divider />
                                    <NavDropdown.Item href="#" onClick={() => {
                                        cookieman.deleteItem('token');
                                        //window.location.href = '/';
                                        setRedirect(<Navigate to="/" />);
                                    }}>Salir</NavDropdown.Item>
                                </NavDropdown>)
                                :
                                (<NavDropdown title="Cuenta" id="basic-nav-dropdown" className="d-flex">
                                    <NavDropdown.Item href="/login">Iniciar Sesión</NavDropdown.Item>
                                </NavDropdown>)
                        }
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </>
    );
};

export default Header;