import {
    Button
} from 'react-bootstrap';

export default (props) => {
    return (
        <Button {...props} style={{
            ...props.style,
            backgroundColor: '#FF8E03',
            borderColor: '#FF8E03'
        }}>
            {props.children}
        </Button>
    );
};