import { Component } from 'react';
import {
    Navbar,
    Container,
} from 'react-bootstrap';


import logo from '../assets/vector/logo.svg';


class Header extends Component {



    render() {
        return (
            <>
                <Navbar expand="lg" >
                    <Container>
                        <Navbar.Brand href="/">
                            <img src={logo} />
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">

                        </Navbar.Collapse>
                    </Container>
                </Navbar>

            </>
        );
    }
}

export default Header;