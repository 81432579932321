//import logo from './logo.svg';
import './App.css';

import React from 'react';
import 'react-bootstrap';
import 'react-notifications/lib/notifications.css';

import Index from './View/Index/index';
import Home from './View/Home/index';
import Login from './View/Usuario/Login';
import Logout from './View/Usuario/Logout';
import AceptarInvitacion from './View/Usuario/AceptarInvitacion';
import Configuracion from './View/Usuario/Configuracion';
import RestablecerContrasena from './View/Usuario/RestablecerContrasena';

import MembresiaAceptarQr from './View/Membresia/Aceptar/Qr';
import MembresiaAceptarCorreo from './View/Membresia/Aceptar/Correo';
import MembresiaIndex from './View/Membresia/Index';

import CondicionesServicio from './View/Legal/condiciones_servicio';
import AvisoDePrivacidad from './View/Legal/aviso_de_privacidad';

import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";



function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />} />
        <Route path="/login" element={<Login />} />
        <Route path="/logout" element={<Logout />} />
        <Route path="/home" element={<Home />} />
        <Route path="/aceptar_invitacion" element={<AceptarInvitacion />} />
        <Route path="/configuracion" element={<Configuracion />} />
        <Route path="/restablecer_contrasena" element={<RestablecerContrasena />} />

        <Route path="/membresia/aceptar/qr" element={<MembresiaAceptarQr />} />
        <Route path="/membresia/aceptar/correo/:token" element={<MembresiaAceptarCorreo />} />
        <Route path="/membresia/:membresia_uuid" element={<MembresiaIndex />}></Route>
        
        <Route path="/terminos-y-condiciones" element={<CondicionesServicio />}></Route>
        <Route path="/aviso-de-privacidad" element={<AvisoDePrivacidad />}></Route>
      </Routes>
    </BrowserRouter>
  );
}

export default App;
