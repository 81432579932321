import React, { Component } from 'react';
import { QrReader } from 'react-qr-reader';

import {
    Container,
    Row,
    Col,
    Alert,
    Spinner
} from 'react-bootstrap';

import { Navigate } from "react-router-dom";

import axios from 'axios';

import config from '../../../config/core';
import cookieman from '../../common/cookieman';
import withNavigation from '../../common/withNavigation';

import Header from '../../Layout/default/header';
import Footer from '../../Layout/default/footer';


class Qr extends Component {
    state = {
        loading: false,
        usuario: null,
        token: '',
        qr: null,
        error: null,
        redirect: null,
    };

    componentDidMount() {
        
    }

    aceptarMembresia(membresia) {
        const url = process.env.REACT_APP_BACKEND + '/membresias/aceptar';

        axios({
            method: 'post',
            url: url,
            headers: {
                token: cookieman.getItem('token')
            },
            data: {
                membresia: membresia
            }
        }).then(response => {
            
            if (response.data.success) {
                this.setState({
                    ...this.state,
                    redirect: <Navigate to="/home" />
                });
            }
            else {
                this.setState({
                    ...this.state,
                    error: response.data.msg,
                    loading: false
                });
            }
        }).catch(e => {
            this.setState({
                ...this.state,
                loading: false
            });
        });
    }

    render() {

        return (
            <>
                {this.state.redirect}
                <Header mustHaveSession={true}></Header>
                <Container style={{minHeight: window.innerHeight *.8}}>
                    <Row>
                        <Col>
                            <p>&nbsp;</p>
                            <p className="text-center">
                                Escanea el código QR de la membresía que te están mostrando
                            </p>

                        </Col>
                    </Row>
                    <Row className="justify-content-md-center" >
                        <Col lg={4} sm={12} className="text-center">
                            {
                                this.state.loading ?
                                    <Spinner animation="border" size="xl" />
                                    :
                                    <QrReader
                                        constraints={{ facingMode: 'environment' }}
                                        onResult={(result, error) => {
                                            
                                            if (!!result) {

                                                this.setState({
                                                    ...this.state,
                                                    loading: true
                                                });


                                                this.aceptarMembresia(result?.text);
                                            }

                                            if (!!error) {
                                                //console.info(error);
                                            }
                                        }}
                                        style={{
                                            width: '1090%',
                                            maxWidth: '400px'
                                        }}
                                    />

                            }
                            {
                                (this.state.error) ? (
                                    <Alert variant="danger" >
                                        <Alert.Heading>Error!</Alert.Heading>
                                        <p>
                                            {this.state.error}
                                        </p>
                                    </Alert>
                                ) : null
                            }
                        </Col>
                    </Row>
                </Container>
                <Footer></Footer>
            </>
        );
    }
}

export default withNavigation(Qr);