import {
    Button
} from 'react-bootstrap';

export default (props) => {
    return (
        <Button {...props} style={{
            ...props.style,
            backgroundColor: '#106CC0',
            borderColor: '#106CC0'
        }}>
            {props.children}
        </Button>
    );
};