import {
    Row,
    Col,
    Container,
    Button
} from 'react-bootstrap';

import './index.css';
import colors from '../common/colors';

import OrangeButton from '../common/Form/OrangeButton';
import WhiteButton from '../common/Form/WhiteButton';
import BlueButton from '../common/Form/BlueButton';

import cookieman from '../common/cookieman';
import jwt_decode from 'jwt-decode';

import image1 from '../assets/image1.png';
import image2 from '../assets/image2.png';
import image3 from '../assets/Group3.png';

import regalo1 from '../assets/regaloSuperks1.png';
import regalo2 from '../assets/regaloSuperks2.png';
import regalo3 from '../assets/regaloSuperks3.png';

import Header from '../Layout/default/header';
import Footer from '../Layout/default/footer';

const Index = () => {
    let session = null;
    if (cookieman.checkItem('token') !== null) {
        session = jwt_decode(cookieman.getItem('token'));
    }

    return (
        <>
            <Header />
            <Container style={{ padding: 0 }}>
                <Row>
                    <Col xs={12} sm={12} md={6} className="d-flex align-items-center">
                        <div id="Section1" style={{ padding: '15px' }} className="align-middle">
                            <p className="d-none d-lg-block d-md-block"><span style={{ color: colors.blue2, fontSize: '39px' }}>Encuentra</span> <span style={{ color: colors.blue1, fontSize: '68px' }}>beneficios</span></p>
                            <p className="d-none d-lg-block d-md-block"><span style={{ color: colors.blue1, fontSize: '39px' }}>en tus establecimientos</span></p>
                            <p className="d-none d-lg-block d-md-block"><span style={{ color: colors.grey1, fontSize: '54px' }}>preferidos</span></p>
                            
                            <p className="text-center d-block d-lg-none d-md-none"><span style={{ color: colors.blue2, fontSize: '39px' }}>Encuentra</span> <span style={{ color: colors.blue1, fontSize: '68px' }}>beneficios</span></p>
                            <p className="text-center d-block d-lg-none d-md-none"><span style={{ color: colors.blue1, fontSize: '39px' }}>en tus establecimientos</span></p>
                            <p className="text-center d-block d-lg-none d-md-none"><span style={{ color: colors.grey1, fontSize: '54px' }}>preferidos</span></p>
                            
                            <div className="text-center" style={{ marginTop: '15px' }}>
                                <Row>
                                    <Col xs={6} sm={6} md={6} lg={6}>
                                        <OrangeButton className="btn-lg" tyle={{fontSize: '19px'}}>Comienza gratis</OrangeButton>
                                    </Col>
                                    <Col xs={6} sm={6} md={6} lg={6}>
                                        <WhiteButton className="btn-lg" style={{fontSize: '19px'}}>¿Cómo funciona?</WhiteButton>
                                    </Col>
                                </Row>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} md={6} className="d-none d-md-block d-lg-block">
                        <img style={{ width: '80%' }} className="float-end" src={image3} />
                    </Col>
                </Row>
            </Container>
            <Container fluid style={{ backgroundColor: colors.grey3, padding: '15px' }}>
                <Container>
                    <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
                        <Col lg={4} sm={12} xs={12} style={{ height: '280px' }}>
                            <p className="text-center" style={{ height: '190px' }} ><img style={{ width: '190px' }} src={regalo1} /></p>
                            <h5 className='text-center'>Descuentos exclusivos</h5>
                            <p className='text-center'>
                                para clientes con membresía
                            </p>
                        </Col>
                        <Col lg={4} sm={12} xs={12} style={{ height: '280px' }}>
                            <p className="text-center" style={{ height: '190px' }} ><img style={{ width: '170px' }} src={regalo2} /></p>
                            <h5 className='text-center'>Cashback en puntos</h5>
                            <p className='text-center'>
                                para canejar en próximas visitas
                            </p>
                        </Col>
                        <Col lg={4} sm={12} xs={12} style={{ height: '280px' }} >
                            <p className="text-center" style={{ height: '190px' }} ><img style={{ width: '140px' }} src={regalo3} /></p>
                            <h5 className='text-center'>Regalos exclusivos</h5>
                            <p className='text-center'>
                                en el mes de tu cumpleaños
                            </p>
                        </Col>
                    </Row>
                </Container>
            </Container>
            <Container>
                <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
                    <Col lg={6} sm={12}>
                        <img style={{ width: '100%' }} src={image1} />
                    </Col>
                    <Col lg={6} sm={12} className='d-flex align-items-center'>
                        <div>
                            <h4>Encuentra el servicio que necesitas</h4>
                            <p>
                                En nuestra red de asicoados en la Ciudad de México.
                            </p>
                            <p>
                                <OrangeButton>Comienza gratis</OrangeButton>
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row style={{ marginTop: '50px', marginBottom: '50px' }}>
                    
                    <Col lg={6} sm={12} className='d-flex align-items-center'>
                        <div>
                            <h4>Administra tus membresias</h4>
                            <p>
                                Superks te permite visualizar a traves de un panel todas tus membresías, así como tus visitas y beneficios acumulados.
                            </p>
                            <p>
                                <BlueButton>Comienza gratis</BlueButton>
                            </p>
                        </div>
                    </Col>
                    <Col lg={6} sm={12}>
                        <img style={{ width: '100%' }} src={image2} />
                    </Col>
                </Row>
            </Container>
            <Footer />
        </>
    );
};

export default Index;