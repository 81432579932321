import {
    Row,
    Col,
    Container
} from 'react-bootstrap';

import colors from '../common/colors';

import Header from './header';
import Footer from '../Layout/default/footer';
import Menu from './menu';

import ContainerAutoHeight from '../common/ContainerAutoHeight';

const Condiciones = () => {
    return (
        <>
            <Header />
            <ContainerAutoHeight style={{ padding: 0 }}>
                <Row>
                    <Col>
                        <h2>
                            <span style={{ color: colors.blue2 }}>Condiciones de Servicio</span>
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row id="Introduccion">
                            <Col>
                                <p>Fecha de entrada en vigor: 1 de diciembre del 2022</p>
                            </Col>
                        </Row>
                        <Row id="TuRelacionConSuperks">
                            <Col>
                                <ol>
                                    <li>Al adquirir una membresía, el cliente acepta los términos y condiciones del sistema.</li>

                                    <li>La membresía es válida por un período de tiempo previamente definido por el dueño del negocio y es intransferible.</li>

                                    <li>El cliente puede obtener un cashback en cada compra realizada en el negocio y descuentos en servicios y productos, siempre y cuando estén disponibles en el momento de la compra.</li>

                                    <li>Los descuentos solo son válidos durante el período de tiempo de validez de la membresía y están sujetos a cambios sin previo aviso por parte del dueño del negocio.</li>

                                    <li>El cliente es responsable de utilizar su membresía de manera adecuada y no puede compartirla con terceros.</li>

                                    <li>El dueño del negocio se reserva el derecho de cancelar o revocar la membresía de un cliente en cualquier momento si se determina que se ha utilizado de manera fraudulenta o inadecuada.</li>

                                    <li>Al adquirir una membresía, el cliente acepta recibir comunicaciones promocionales y de marketing relacionadas con el negocio y el sistema de membresías.</li>

                                    <li>Estos términos y condiciones están sujetos a cambios sin previo aviso y es responsabilidad del cliente revisarlos periódicamente.</li>

                                    <li>Al adquirir una membresía, el cliente acepta estos términos y condiciones y se compromete a cumplirlos.</li>
                                </ol>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ContainerAutoHeight>
            <Footer />
        </>
    );
};

export default Condiciones;