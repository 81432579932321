import {
    Container,
    Row,
    Col
} from 'react-bootstrap';

import logo from '../../assets/vector/logo.svg';

const Header = () => {
    return (
        <Container className="d-block d-md-none d-lg-none">
            <Row>
                <Col className="text-center" style={{
                    padding: '15px'
                }}>
                    <a href="/home"><img src={logo} /></a>
                </Col>
            </Row>
        </Container>
    );
};

export default Header;