import {
    Row,
    Col,
    Container
} from 'react-bootstrap';

import colors from '../common/colors';

import Header from './header';
import Footer from '../Layout/default/footer';
import Menu from './menu';

import ContainerAutoHeight from '../common/ContainerAutoHeight';

const Condiciones = () => {
    return (
        <>
            <Header />
            <ContainerAutoHeight style={{ padding: 0 }}>
                <Row>
                    <Col>
                        <h2>
                            <span style={{ color: colors.blue2 }}>Aviso de privacidad</span>
                        </h2>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Row id="Introduccion">
                            <Col>
                                <p>Fecha de entrada en vigor: 1 de diciembre del 2022</p>
                            </Col>
                        </Row>
                        <Row id="TuRelacionConSuperks">
                            <Col>
                                <p>
                                    En Superks, nos tomamos en serio la protección de su información personal. Al utilizar nuestro sistema de membresías, usted nos proporciona información personal, por lo que es importante que entienda cómo la protegemos.
                                </p>
                                <p>
                                    Este aviso de privacidad explica cómo recopilamos, usamos, compartimos y protegemos su información personal. Por favor, lee cuidadosamente antes de utilizar el sistema de membresías.
                                </p>
                                <ol>
                                    <li>Recopilación de información: Recopilamos su información personal cuando se registra en el sistema de membresías, incluyendo su nombre, correo electrónico y teléfono. También recopilamos información sobre sus compras y los descuentos redimidos a través del sistema.</li>

                                    <li>Uso de información: Utilizamos su información personal para brindarle acceso al sistema de membresías, ofrecerle descuentos y para enviarle información relevante sobre nuestros productos y servicios. También utilizamos su información para mejorar nuestros productos y servicios y para realizar análisis de mercado.</li>

                                    <li>Compartir información: No compartimos su información personal con terceros sin su consentimiento, excepto en los casos en que sea requerido por ley o para proteger los derechos de Superks.</li>

                                    <li>Protección de información: Tomamos medidas de seguridad para proteger su información personal, incluyendo el uso de encriptación y la restricción del acceso a su información solo a empleados autorizados y negocios de los que tengas membresía activa o inactiva.</li>

                                    <li>Acceso a información: Usted puede solicitar acceso a su información personal en cualquier momento y solicitar su corrección o eliminación al correo <a href="mailto:cesar.gasca.andrade@gmail.com">cesar.gasca.andrade@gmail.com</a>.</li>

                                    <li>Modificaciones a este aviso de privacidad: Nos reservamos el derecho de modificar este aviso de privacidad en cualquier momento. En caso de que se realice un cambio importante, le notificaremos por correo electrónico o a través de un aviso en nuestro sitio web.</li>

                                    <li>Preguntas sobre la privacidad: Si tiene alguna pregunta o preocupación sobre nuestras prácticas de privacidad, no dude en ponerse en contacto con nosotros al correo <a href="mailto:cesar.gasca.andrade@gmail.com">cesar.gasca.andrade@gmail.com</a>.</li>
                                </ol>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </ContainerAutoHeight>
            <Footer />
        </>
    );
};

export default Condiciones;