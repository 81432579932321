import React, { Component } from 'react';

import {
    Container,
    Row,
    Col,
    Card,
    Form,
    Button,
    FloatingLabel,
    Spinner
} from 'react-bootstrap';

import { validate } from 'validate.js';

import axios from 'axios';

import { NotificationContainer, NotificationManager } from 'react-notifications';
import { Navigate } from 'react-router-dom';

import config from '../../config/core';
import ContainerAutoHeight from '../common/ContainerAutoHeight';

import Header from '../Layout/default/header';
import Footer from '../Layout/default/footer';

import withNavigation from '../common/withNavigation';
import loadingOverlay from '../common/loadingOverlay';


class RestablecerContrasena extends Component {
    state = {
        fields: {
            password: {
                value: '',
                isInvalid: false,
                error: null
            },
            confirm_password: {
                value: '',
                isInvalid: false,
                error: null
            },
        },
        loading: true,
        redirect: null
    }

    formConstraints = {
        password: {
            presence: {
                allowEmpty: false
            },
            length: {
                minimum: 8,
                maximum: 45
            }
        },
        confirm_password: {
            presence: {
                allowEmpty: false
            },
            equality: 'password'
        }
    };

    componentDidMount() {
        // validar token
        const url = process.env.REACT_APP_BACKEND + '/usuarios/restablecer_contrasena/is_valid_token?token=' + this.props.searchParams[0].get('t');

        axios.get(url).then(response => {
            this.setState({
                ...this.state,
                loading: false
            });
        }).catch(error => {
            this.setState({
                ...this.state,
                redirect: <Navigate to="/" />
            });
        });
    }


    setField = (field, value) => {
        const newState = { ...this.state };
        newState.fields[field].value = value;

        this.setState(newState);
    }

    setData = (data) => {
        const newState = {
            ...this.state,
            ...data
        };

        this.setState(newState);
    }

    validateField = (field) => {
        const constraint = {};

        constraint[field] = this.formConstraints[field];

        const data = {};

        data[field] = this.state.fields[field].value;

        if (typeof this.formConstraints[field].equality !== 'undefined') {
            data[this.formConstraints[field].equality] = this.state.fields[this.formConstraints[field].equality].value;
        }

        const validacion = validate(data, constraint);

        const newState = { ...this.state };

        if (typeof validacion !== 'undefined') {
            newState.fields[field].isInvalid = true;
            newState.fields[field].error = (
                <ul>
                    {
                        validacion[field].map(error => {
                            return (<li>{error}</li>);
                        })
                    }
                </ul>
            );

        }
        else {
            newState.fields[field].isInvalid = false;
            newState.fields[field].error = null;

        }
        this.setState(newState);
    }

    restablecerContrasena = () => {
        // validar formulario
        const data = {
            password: this.state.fields.password.value,
            confirm_password: this.state.fields.confirm_password.value
        };

        const validation = validate(data, this.formConstraints);

        if (typeof validation !== 'undefined') {
            // notificar errores 
            Object.keys(data).forEach(field => {
                this.validateField(field);
            });
        }
        else {
            const url = process.env.REACT_APP_BACKEND + '/usuarios/restablecer_contrasena';
            this.setData('loading', true);
            axios.put(url, {
                token: this.props.searchParams[0].get('t'),
                password: this.state.fields.password.value,
                confirm_password: this.state.fields.confirm_password.value,
            }).then(response => {

                NotificationManager.success(response.data.msg, 'Contraseña restablecida');

                this.setState({
                    ...this.state,
                    loading: true
                });

                setTimeout(() => {
                    this.setState({
                        ...this.state,
                        redirect: <Navigate to="/login" />
                    });
                }, 3000);

            }).catch(error => {
                this.setData('loading', true);
            });
        }
    }

    render() {
        const login_form = (
            <>
                {this.state.loading ? loadingOverlay() : null}
                <ContainerAutoHeight>
                    <Row className="justify-content-md-center">
                        <Col>
                            <h1 className="text-center">&nbsp;</h1>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col lg={4} sm={12}>
                            <Card className="m1">
                                <Card.Title className="mt-3 mb-3">
                                    <h2 className="text-center">Restablecer Contraseña</h2>
                                </Card.Title>
                                <Card.Body>
                                    <Form>

                                        <Form.Group className="mb-3" controlId="FormPassword">
                                            <FloatingLabel controlId="Password" label="Contraseña">
                                                <Form.Control
                                                    type="password"
                                                    isInvalid={this.state.fields.password.isInvalid}
                                                    disabled={this.loading}
                                                    placeholder="Contraseña"
                                                    onChange={e => {
                                                        this.setField('password', e.target.value);

                                                        if (this.state.fields.password.isInvalid) {
                                                            this.validateField('password');
                                                        }
                                                    }}
                                                    onBlur={() => {
                                                        this.validateField('password');
                                                    }} />
                                                <Form.Control.Feedback type="invalid">{this.state.fields.password.error}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Form.Group>
                                        <Form.Group className="mb-3" controlId="FormConfirmPassword">
                                            <FloatingLabel controlId="ConfirmPassword" label="Confirmar Contraseña">
                                                <Form.Control
                                                    type="password"
                                                    isInvalid={this.state.fields.confirm_password.isInvalid}
                                                    disabled={this.loading}
                                                    placeholder="Confirmar Contraseña"
                                                    onChange={e => {
                                                        this.setField('confirm_password', e.target.value);

                                                        if (this.state.fields.confirm_password.isInvalid) {
                                                            this.validateField('confirm_password');
                                                        }
                                                    }}
                                                    onBlur={() => {
                                                        this.validateField('confirm_password');
                                                    }} />
                                                <Form.Control.Feedback type="invalid">{this.state.fields.confirm_password.error}</Form.Control.Feedback>
                                            </FloatingLabel>
                                        </Form.Group>
                                        <div className="d-grid gap-2">
                                            <Button variant="primary" disabled={this.loading} type="button" size="lg" onClick={() => {
                                                this.restablecerContrasena();
                                            }}>
                                                {this.loading ? (<Spinner animation="border" variant="light" />) : 'Continuar'}
                                            </Button>

                                        </div>
                                    </Form>
                                </Card.Body>
                            </Card>
                        </Col>
                    </Row>
                </ContainerAutoHeight>
                <NotificationContainer />
            </>
        );

        return (<>
            <Header />
            {this.state.redirect}
            {login_form}
            <Footer />
        </>);
    }

}

export default withNavigation(RestablecerContrasena);