import cookieman from './cookieman';
import axios from 'axios';

import config from '../../config/core';

export default async () => {
    const url = config.backend + '/usuarios/is_valid_session';

    let isValid = true;

    await axios({
        url: url,
        type: 'get',
        headers: {
            token: cookieman.getItem('token')
        },
    }).catch(error => {
        cookieman.deleteItem('token');
        
        isValid = false;
    });

    if(isValid) {
        cookieman.setItem('nextSessionCheck', 0);
    }
    
    return isValid;
}